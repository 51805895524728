/**
 * Init Guarantees accordions
 */

const accordionsGuarantees = Array.from(
  document.querySelectorAll(".block-guarantees__grid")
);

if (accordionsGuarantees.length > 0) {
  new Accordion( accordionsGuarantees );
}
