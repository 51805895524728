/**
 * Handle the custom resource dropdown filters.
 */

// Get the button, and when the user clicks on it, toggle class "open" <- AI generated "wow" ;)
const selectWrapper = document.querySelector(".custom-select-wrapper");

if( selectWrapper !== null ) {

  selectWrapper.addEventListener("click", function () {
    this.querySelector(".custom-select").classList.toggle("open");
  });

  // Close the dropdown if the user clicks outside of it
  window.addEventListener("click", function (e) {
    for (const select of document.querySelectorAll(".custom-select")) {
      if (!select.contains(e.target)) {
        select.classList.remove("open");
      }
    }
  });

}
