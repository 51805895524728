/**
 * Single product page scripts
 *
 * Navbar scroll
 * ScrollSpy
 */

const scrollNavbar = () => {
  const productNavbar = document.querySelector(".product-navbar");

  if (productNavbar) {
    const productNavbarPosition = productNavbar.offsetTop;
    const offset = 176; // header height

    changeScrollClass(productNavbarPosition);

    window.addEventListener("scroll", () => {
      changeScrollClass(productNavbarPosition);
    });

    // declare ScrollSpy
    const options = {
      sectionSelector: ".section-layout",
      targetSelector: ".product-summary__item",
      activeClass: "product-summary__item--active",
      offset: offset,
    };

    scrollSpy(productNavbar, options);
  }

  function changeScrollClass(productNavbarPosition) {
    let windowPosition = window.scrollY;

    if (productNavbarPosition <= windowPosition) {
      productNavbar.classList.add("product-navbar--scrolled");
    } else {
      productNavbar.classList.remove("product-navbar--scrolled");
    }
  }
};
scrollNavbar();
