/**
 * Sticky navbar in FAQ page
 */

// Get the navbar element

window.onload = function () {

  const summary = document.querySelector(".resource-summary");

  if (summary) {

    const summaryPosition = summary.offsetTop;
    const offset = 176; // header height

    changeScrollClass( summaryPosition );


    window.addEventListener("scroll", function () {
      changeScrollClass( summaryPosition );
    });

    const options = {
      sectionSelector: ".wp-block-group",
      targetSelector: ".button--summary",
      activeClass: "is-active",
      offset: offset,
    };

    scrollSpy(summary, options);

  }

  function changeScrollClass( summaryPosition ) {

    let windowPosition = window.scrollY;

    if ( windowPosition >= summaryPosition ) {
      summary.classList.add("is-fixed");
    } else {
      summary.classList.remove("is-fixed");
    }

  }

};


// TODO: clean
// if (summary !== null) {
//   // Get the offset position of the navbar
//   const offset = summary.offsetTop;

//   // Add a scroll event listener
//   window.addEventListener("scroll", () => {
//     // Check if the current window position is below the navbar
//     if (window.pageYOffset >= offset) {
//       // If it is, add the "fixed" class to the navbar
//       summary.classList.add("is-fixed");
//     } else {
//       // Otherwise, remove the "fixed" class
//       summary.classList.remove("is-fixed");
//     }

//     // Get all the sections on the page
//     const sections = document.querySelectorAll('[class*="wp-block-"]');

//     // Loop through each section and check its position
//     sections.forEach((section) => {
//       if( section.id === "" ) return;
//       const sectionTop = section.offsetTop;
//       const sectionBottom = sectionTop + section.offsetHeight;

//       // If the current window position is inside the section, add the "active" class to the navbar item
//       if (
//         window.pageYOffset >= sectionTop &&
//         window.pageYOffset < sectionBottom &&
//         section.id !== ""
//       ) {
//         const navbarItem = document.querySelector(`[href="#${section.id}"]`);
//         navbarItem.classList.add("is-active");
//       } else {
//         // Otherwise, remove the "active" class
//         const navbarItem = document.querySelector(`[href="#${section.id}"]`);
//         navbarItem.classList.remove("is-active");
//       }
//     });
//   });
// }
