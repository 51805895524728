/**
 * Init FAQ post type accordions
 */

const accordionsFaq = Array.from(
  document.querySelectorAll(".accordion-container")
);

if (accordionsFaq.length > 0) {

  new Accordion(accordionsFaq, {
    openOnInit: [0],
  });

}
