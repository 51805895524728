/**
 * Sticky navbar in FAQ page
 */

// Get the navbar element
const navbar = document.getElementById("faq-nav");

if (navbar !== null) {
  // Get the offset position of the navbar
  const offset = navbar.offsetTop;

  // Add a scroll event listener
  window.addEventListener("scroll", () => {
    // Check if the current window position is below the navbar
    if (window.scrollY >= offset) {
      // If it is, add the "fixed" class to the navbar
      navbar.classList.add("is-fixed");
    } else {
      // Otherwise, remove the "fixed" class
      navbar.classList.remove("is-fixed");
    }

    // Get all the sections on the page
    const sections = document.querySelectorAll(".faq-container");
    // Get the height of the navbar
    const navbarHeight = navbar.offsetHeight * 2;

    // Loop through each section and check its position
    sections.forEach((section) => {
      const sectionTop = section.offsetTop - navbarHeight;
      const sectionBottom = sectionTop + section.offsetHeight;

      // If the current window position is inside the section, add the "active" class to the navbar item
      if (window.scrollY >= sectionTop && window.scrollY < sectionBottom) {
        const navbarItem = document.querySelector(`[href="#${section.id}"]`);
        navbarItem.classList.add("is-active");
      } else {
        // Otherwise, remove the "active" class
        const navbarItem = document.querySelector(`[href="#${section.id}"]`);
        navbarItem.classList.remove("is-active");
      }
    });
  });
}
