/* ==========================================================================
  main.js
========================================================================== */

"use strict";
/* global fitvids, cssVars */

/**
 * Init CSS vars ponyfill
 */
cssVars({});

/**
 * Header : Add class on scroll
 */
const scrollHeader = () => {

  const header = document.querySelector(".site-header");

  if( header ) {

    const headerPosition = header.getBoundingClientRect();

    changeScrollClass();

    window.addEventListener("scroll", () => {
      changeScrollClass();
    });

  }

  function changeScrollClass() {

    const scrollChange = 1; // add fixed > 1px

    let windowPosition = window.scrollY;

    if (windowPosition >= scrollChange) {
      header.classList.add("scroll");
    } else {
      header.classList.remove("scroll");
    }
  }

};
scrollHeader();

/**
 * Video container handle cover image onClick
 */
const videoPoster = () => {

  const videoPosters = document.querySelectorAll(".video-container__poster");

  if( videoPosters.length > 0 ) {
    videoPosters.forEach( function( videoPoster ) {
      const video = videoPoster.nextElementSibling;
      videoPoster.addEventListener("click", function (e) {
        e.preventDefault();
        if (this.classList.contains("video-container__poster--active")) {
          this.classList.remove("video-container__poster--active");
          video.play();
        } else {
          this.classList.add("video-container__poster--active");
          video.pause();
        }
      });
    });
  }
};
videoPoster();

const toggleSummary = () => {

  const summaryToggle = document.querySelector(".toggle-summary");

  if( summaryToggle ) {

    const summary = summaryToggle.nextElementSibling;

    summaryToggle.addEventListener("click", function (e) {
      e.preventDefault();
      this.classList.toggle("toggle-summary--active");
      summary.classList.toggle("summary--active");
    });

    // close menu summary on click
    const menuItems = document.querySelectorAll(".button--faq");

    if( menuItems.length > 0 ) {
      menuItems.forEach( function( menuItem ) {
        menuItem.addEventListener("click", function (e) {
          summaryToggle.classList.remove("toggle-summary--active");
          summary.classList.remove("summary--active");
        });
      });
    }

  }
};

toggleSummary();
