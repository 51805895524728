/**
 * Init Guarantees accordions
 */

function startCounterAnimation(element) {
  // Get the target value from the data-target attribute
  const targetValue = parseInt(element.getAttribute("data-target"));

  let currentValue = 0;

  const updateCounter = function () {
    // Increment the current value by a small amount
    currentValue += Math.ceil(targetValue / 100);

    // Update the text content of the element
    element.textContent = currentValue;

    // Stop the animation when the target value is reached
    if (currentValue >= targetValue) {
      currentValue = targetValue;
      clearInterval(counterInterval);
    }
  };

  // Set an interval to update the counter
  const counterInterval = setInterval(updateCounter, 30);
}

/**
 * Animated counter on scroll for reinssurance section
 */
document.addEventListener("DOMContentLoaded", function () {
  // Target all elements with the class "counter"
  const counterElements = document.querySelectorAll(".animated-counter");

  // Options for the Intersection Observer
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  };

  // Callback function for the Intersection Observer
  const callback = function (entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Start the counter animation for each element
        startCounterAnimation(entry.target);
      }
    });
  };

  // Create an Intersection Observer
  const observer = new IntersectionObserver(callback, options);

  // Start observing each counter element
  counterElements.forEach((element) => {
    observer.observe(element);
  });
});
